import React from 'react';
import { graphql, Link, navigate } from 'gatsby';
import Gallery from '@browniebroke/gatsby-image-gallery';
import Layout from '../../components/Layout';
import ScrollToTop from 'react-scroll-up';
import { useEffect } from 'react';

const Wedding = ({data, location}) => {
    console.log(data)
  const images = data.engagement.nodes.map((item) => {
    return {
      thumb: item?.file?.childImageSharp.thumb,
      full: item?.file?.childImageSharp.full,
    };
  });

  return (
    <Layout fullMenu location={location} title="Wedding Photos">
      <article id="main">
        <header className="photos-header">
          <h2>Wedding</h2>
          <p>
            Photographer:{' '}
            <a
              href="https://www.blackframephotos.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Black Frame Photos
            </a>
          </p>
        </header>
        <section className="wrapper style5">
          <div className="inner">
            <div className="box alt">
              <p>
                <Link to="/photos">&larr; Back to Photos</Link>
              </p>
              <p>
                If you have photos you would like to share, please click the button to upload!
              </p>
              <a href="https://photos.app.goo.gl/om8DwDkqSPJ5JqLm7" target='_'>
              <Link
                  className="button primary wedding-button"
                  >
                  Upload Photos
              </Link>
              </a>
              <hr />
              <div className="gallerya">
                <Gallery images={images} colWidth={100} mdColWidth={25} />
              </div>
            </div>
          </div>
        </section>
      </article>
      <ScrollToTop showUnder={160} duration={700}>
        <div className="button primary scroll-up-button">
          <span className="icon solid fa-arrow-up" />
        </div>
      </ScrollToTop>
    </Layout>
  );
};

// export const pageQuery = graphql`
//     query {
//         allGooglePhotosAlbum {
//             nodes {
//                 title
//                 cover {
//                     file {
//                         childImageSharp {
//                             gatsbyImageData
//                         }
//                     }
//                 }
//                 photos {
//                     file {
//                         childImageSharp {
//                             gatsbyImageData
//                         }
//                     }
//                 }
//             }
//         }
//     }
// `

export const pageQuery = graphql`
  {
    engagement: allGooglePhotosPhoto(
      filter: { album: { title: { eq: "Wedding Ceremony" } } }
    ) {
      nodes {
        file {
          childImageSharp {
            thumb: gatsbyImageData(
              width: 270
              height: 270
              placeholder: BLURRED
            )
            full: gatsbyImageData
          }
        }
      }
    }
  }
`;

// export const pageQuery = graphql`
//   {
//     weddingPhotosPage: allGooglePhotosPhoto(
//       filter: { album: { title: { eq: "Wedding - Engagement" } } }
//     ) {
//       nodes {
//         file {
//           childImageSharp {
//             thumb: gatsbyImageData(
//               width: 270
//               height: 270
//               placeholder: BLURRED
//             )
//             full: gatsbyImageData
//           }
//         }
//       }
//     }
//   }
// `;

export default Wedding;
